/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.login-button-row {
    display: table-cell;
    max-width: 600px;
  }

.mat-dialog-login-button-close {
    justify-content: flex-end;
}